import { useState } from 'react';
import styles from './style.module.scss';
import ChangesLog from 'components/atoms/ChangesLog';

export default function InputTextLog({
  id,
  name,
  key = '',
  type,
  disabled,
  placeholder,
  maxLength = 524288,
  value = '',
  min,
  prevValue,
  onChange,
  onKeyDown,
  wasChanged,
  approved,
  changed,
  dismiss,
  convertToTimezone,
  max
}) {
  const preventUpperCase =
    type === 'email' ? { textTransform: 'lowercase' } : {};

  const previousValue = type === 'number' ? prevValue?.toString() : prevValue;
  const [showLog, setShowLog] = useState(false);
  const applyClass = wasChanged
    ? approved
      ? styles.approved
      : dismiss
      ? styles.dismiss
      : changed
      ? styles.changed
      : styles.requireReview
    : approved
    ? styles.approved
    : dismiss
    ? styles.dismiss
    : '';

  let newValue;
  if (type === 'date' && value && convertToTimezone) {
    newValue = new Date(value);
    const timezoneOffSet = newValue.getTimezoneOffset();
    newValue.setMinutes(
      newValue.getMinutes() + (timezoneOffSet < 0 ? 0 : timezoneOffSet)
    );
  }

  return (
    <div className={styles.container} key={key}>
      <input
        className={applyClass}
        id={id}
        name={name}
        type={type}
        min={min}
        max={max}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={value}
        maxLength={maxLength}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onWheel={(e) => e.target.blur()}
        style={preventUpperCase}
      />

      {wasChanged && previousValue && (
        <ChangesLog
          showLog={showLog}
          setShowLog={setShowLog}
          prevValue={previousValue}
          className="absolute top-[20px] right-[16px]"
        />
      )}
    </div>
  );
}
