import { createContext, useContext } from 'react';
import { ContextTypes } from './types';

export const DialogContext = createContext<ContextTypes | null>(null);

export const useDialog = () => {
  const dialogData = useContext(DialogContext);
  if (dialogData == null) {
    throw Error('A Dialog must have a Dialog parent');
  }
  return dialogData;
};
