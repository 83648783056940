import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';

export default function InputTextArea({
  label = '',
  id,
  disabled = false,
  placeholder,
  description = '',
  value = '',
  required = false,
  fullWidth = false,
  onChange,
  onError = (a, b) => {},
  checkErrorOnFirstRender = false,
  wasChanged = false,
  disabledComment = false
}) {
  const { t } = useTranslation();
  const [checkError, setCheckError] = useState(checkErrorOnFirstRender);
  const [error, setError] = useState(
    checkErrorOnFirstRender && required && String(value).trim() === ''
  );

  useEffect(() => {
    const hasError = required && String(value).trim() === '';
    setError(checkError && hasError);
    onError && onError(hasError, id);
    setCheckError(true);
  }, [value]);

  return (
    <fieldset
      className={`${styles.fieldset} ${fullWidth ? styles.full_width : ''} ${
        error ? 'error' : ''
      }`}
    >
      {label && (
        <label
          className={`${
            wasChanged ? styles.requireReview : ''
          } !text-[#1f384c]`}
          htmlFor={id}
        >
          {`${label}`}
        </label>
      )}
      {required && (
        <span className={'text-red-500 ml-[2px] leading-[18px] text-[16px]'}>
          *
        </span>
      )}
      {description && <small>{description}</small>}
      {disabled && !disabledComment ? (
        <p className={styles.disabled}>{value}</p>
      ) : (
        <>
          <textarea
            id={id}
            disabled={disabledComment}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
          {error && <span>{t('required_field')}</span>}
        </>
      )}
    </fieldset>
  );
}
