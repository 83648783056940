import { useEffect } from 'react';
import { Trim } from 'shared/utils';

let modals: string[] = [];

/* Handle the order of the modals */
export const modalManager = {
  /* Register a new modal */
  add: (newModal: string) => {
    modals.push(newModal);
  },

  /* Remove a modal */
  remove: (oldModal: string) => {
    modals = modals.filter((modal) => modal !== oldModal);
  },

  /* When multiple modals are rendered will return true if current modal is the last one */
  isTopModal: (modal: string) => {
    if (!modals.length) return false;
    return Trim(modals[modals.length - 1]) === Trim(modal);
  }
};

export function useModalManager(id: string, open: boolean) {
  useEffect(() => {
    if (open && id) {
      modalManager.add(id);
    }
    return () => {
      if (!id) return;
      modalManager.remove(id);
    };
  }, [open, id]);
}
