'use client';
import styles from './style.module.scss';
import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogScrollWrapper
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { api } from 'services/api';
import * as ga from 'lib/ga';
import InputTextPV from 'components/molecules/InputTextPV';
import InputTextArea from 'components/molecules/InputTextArea';
import { BROADCOM_PRIVACY_POLICY_URL } from 'shared/constants';
import { useMutation } from '@tanstack/react-query';
import { validateEmail } from 'shared/validations';
import InputPhonePV from 'components/molecules/InputPhonePV';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { toast } from 'sonner';

const initialFormState = {
  userName: '',
  companyName: '',
  emailAddress: '',
  phoneNumber: '',
  subject: '',
  message: ''
};

type ComponentProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<any>>;
  partnerName: any;
  partnerEmail: any;
  partnerInfo?: boolean;
  offeringInfo?: boolean;
};

export default function SendEmailForm({
  open,
  setOpen,
  partnerName,
  partnerEmail,
  partnerInfo = false,
  offeringInfo = false
}: ComponentProps) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormState);
  const [isFormValid, setFormValid] = useState(false);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  // Update state on input change
  function handleInputChange(input) {
    const { id, value } = input;

    const newData = { ...formData, [id]: value };
    setFormData(newData);

    if (
      !!newData.userName &&
      !!newData.companyName &&
      !!newData.subject &&
      !!newData.message &&
      !!newData.emailAddress
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }

  function onCancel() {
    setOpen(false);
    setFormData(initialFormState);
    setFormValid(false);
  }

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: () => {
      return api.post(`/sendEmail/partner`, {
        ...formData,
        subject:
          (partnerInfo && `Customer inquiry - ${formData.subject}`) ||
          (offeringInfo &&
            `Customer inquiry on Marketplace Integration - ${formData.subject}`) ||
          formData.subject,
        partnerEmail: partnerEmail,
        message: formData.message,
        userName: formData.userName,
        companyName: formData.companyName,
        emailAddress: formData.emailAddress,
        phoneNumber: formData.phoneNumber,
        partnerName: partnerName
      });
    },
    onSuccess: () => {
      toast.success('Email sent');
      ga.event({
        action: 'search',
        params: {
          search_term: `Email sent to (${partnerName})`
        }
      });
      setOpen(false);
      setFormData(initialFormState);
      setFormValid(false);
    },
    onError: (error: any) => {
      toast.error('Error sending the email', { description: error || '' });
      ga.event({
        action: 'search',
        params: {
          search_term: `Email not sent to (${partnerName}) due to an error`
        }
      });
    }
  });

  function submitForm() {
    if (formData.userName.length < 2) {
      return toast.error('Name must be at least 2 characters');
    }

    const [valid, message] = validateEmail(formData.emailAddress);
    if (!valid) {
      return toast.error(message);
    }

    if (formData.phoneNumber && !isPossiblePhoneNumber(formData.phoneNumber)) {
      return toast.error(t('invalid_phone'));
    }

    mutate();
  }

  const formContent = (
    <>
      <div className={`grid_container two_columns ${styles.grid}`}>
        <InputTextPV
          id="userName"
          value={formData?.userName}
          label={t('your_name')}
          placeholder={t('your_name')}
          type="text"
          onChange={(event) => handleInputChange(event.target)}
          required
        />
        <InputTextPV
          id="companyName"
          value={formData?.companyName}
          label={t('your_comp_name')}
          placeholder={t('your_comp_name')}
          type="text"
          onChange={(event) => handleInputChange(event.target)}
          required
        />
      </div>
      <div className={`grid_container two_columns ${styles.grid}`}>
        <InputTextPV
          id="emailAddress"
          value={formData?.emailAddress}
          label={t('your_email')}
          placeholder={t('your_email')}
          type="email"
          onChange={(event) => handleInputChange(event.target)}
          required
        />
        <InputPhonePV
          id="phoneNumber"
          value={formData?.phoneNumber}
          label={t('your_phone_number')}
          placeholder={t('your_phone_number')}
          onChange={(value) => {
            handleInputChange({ id: 'phoneNumber', value });
          }}
        />
      </div>
      <div className={`flex flex-col gap-6 ${styles.grid}`}>
        <InputTextPV
          id="subject"
          value={formData?.subject}
          label={t('subject')}
          placeholder={t('your_subject')}
          type="text"
          fullWidth
          onChange={(event) => handleInputChange(event.target)}
          required
          maxLength={70}
        />
        <InputTextArea
          id="message"
          label={t('message')}
          placeholder={t('your_message')}
          value={formData?.message}
          fullWidth
          onChange={(event) => handleInputChange(event.target)}
          required
          disabled={undefined}
          description={undefined}
          onError={undefined}
          wasChanged={undefined}
        />
      </div>
    </>
  );

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const size = screenSize.width < 450 && screenSize.height < 813;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        disabled={isLoading}
        className={`md:max-w-4xl ${size && 'max-h-[100%]'} ${
          screenSize.width < 640 && 'pb-24'
        }`}
      >
        <DialogHeader>
          <DialogTitle className={`${size && 'pt-8'}`}>
            {t('send_email_to')} {partnerName ? partnerName : partnerEmail}
          </DialogTitle>
          <DialogDescription className="leading-relaxed">
            {t('submit_email_consent')}{' '}
            <a
              target="_blank"
              className="text-lg"
              href={BROADCOM_PRIVACY_POLICY_URL}
            >
              {t('bc_privacy_policy')}
            </a>
          </DialogDescription>
        </DialogHeader>

        {size ? (
          <>{formContent}</>
        ) : (
          <DialogScrollWrapper className="flex flex-col gap-6">
            {formContent}
          </DialogScrollWrapper>
        )}
        <DialogFooter
          className={`${styles.button_wrapper} ${
            screenSize.width < 640 && 'gap-4'
          }`}
        >
          <Button
            disabled={!isFormValid}
            loading={isLoading}
            onClick={() => submitForm()}
          >
            {t('confirm')}
          </Button>
          <Button
            variant="ghost"
            disabled={isLoading}
            onClick={() => onCancel()}
          >
            {t('cancel')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
