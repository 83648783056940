import { DialogPrimitive } from './primitives';

export const Dialog = DialogPrimitive.Dialog;
export const DialogContent = DialogPrimitive.DialogContent;
export const DialogPortal = DialogPrimitive.DialogPortal;
export const DialogOverlay = DialogPrimitive.DialogOverlay;
export const DialogHeader = DialogPrimitive.DialogHeader;
export const DialogFooter = DialogPrimitive.DialogFooter;
export const DialogTitle = DialogPrimitive.DialogTitle;
export const DialogDescription = DialogPrimitive.DialogDescription;
export const DialogScrollWrapper = DialogPrimitive.DialogScrollWrapper;
