import { cn } from 'lib/utils';
import { ElementRef, forwardRef, useEffect, useMemo, useRef } from 'react';
import IconTrash from '/public/images/delete_bin_modal.svg';
import DeliveryComplete from '/public/images/delivery_complete_icon.svg';
import KnightsFeature from '/public/images/kights_feature_modal_icon.svg';
import KnightsFeatureReplace from '/public/images/kights_feature_replace_modal_icon.svg';
import SurveyDelegateModal from '/public/images/survey_delegate_modal_icon.svg';
import CreateIconModal from '/public/images/blog_post_modal_icon.svg';
import EditIconModal from '/public/images/blog_post_edit_modal_icon.svg';
import AwardIconModal from '/public/images/award_icon_modal.svg';
import RequestIconModal from '/public/images/request_icon_modal.svg';
import CalendarIcon from '/public/images/calendar_icon.svg';
import DownloadIcon from '/public/images/download-modal-icon.svg';
import SaveModal from '/public/images/save_modal.svg';
import AddPerson from '/public/images/add_person.svg';

import {
  ContentTypes,
  DescriptionTypes,
  DialogTypes,
  FooterTypes,
  OverlayTypes,
  TitleTypes
} from './types';
import { DialogContext, useDialog } from './context';
import { createPortal } from 'react-dom';
import { Button } from '../button';
import { X } from 'lucide-react';
import styles from './style.module.scss';
import useOutsideClickMultiple from 'hooks/useOutsideClickMultiple';
import { modalManager, useModalManager } from './dialogManager';
const safeDocument: any = typeof document !== 'undefined' ? document : {};
const Dialog = ({
  open = false,
  onOpenChange = () => {},
  children,
  defaultOpen = false
}: DialogTypes) => {
  useEffect(() => {
    if (defaultOpen) onOpenChange(true);
    return () => {
      //prevent hidden scroll after closing modal
      safeDocument.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <DialogContext.Provider value={{ isOpen: open, setIsOpen: onOpenChange }}>
      {children}
    </DialogContext.Provider>
  );
};
const DialogPortal = ({ children }) => {
  return createPortal(children, document.body);
};
const DialogOverlay = forwardRef<ElementRef<'div'>, OverlayTypes>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'fixed box-border inset-0 z-[99999] overflow-y-auto overflow-x-hidden w-screen h-screen py-6 grid place-items-center bg-black/20',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
);

const getDialogTitleIcon = (icon?: string) => {
  switch (icon) {
    case 'delete':
      return (
        <span>
          <IconTrash />
        </span>
      );
    case 'del_complete':
      return (
        <span>
          <DeliveryComplete />
        </span>
      );
    case 'knights_feature':
      return (
        <span>
          <KnightsFeature />
        </span>
      );
    case 'knights_feature_replace':
      return (
        <span>
          <KnightsFeatureReplace />
        </span>
      );
    case 'survey_delegate':
      return (
        <span>
          <SurveyDelegateModal />
        </span>
      );
    case 'create_icon':
      return (
        <span>
          <CreateIconModal />
        </span>
      );
    case 'edit_icon':
      return (
        <span>
          <EditIconModal />
        </span>
      );
    case 'award_icon':
      return (
        <span>
          <AwardIconModal />
        </span>
      );
    case 'request_icon':
      return (
        <span>
          <RequestIconModal />
        </span>
      );
    case 'calendar_icon':
      return (
        <span>
          <CalendarIcon />
        </span>
      );
    case 'download':
      return (
        <span>
          <DownloadIcon />
        </span>
      );
    case 'save':
      return (
        <span>
          <SaveModal />
        </span>
      );
    case 'add_person':
      return (
        <span>
          <AddPerson />
        </span>
      );
    default:
      return <></>;
  }
};

const Close = ({
  onClose,
  disabled
}: {
  onClose: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button
      className="absolute flex justify-center items-center cursor-pointer w-[47px] h-[47px] drop-shadow-[0_0px_5px_#1F384C1A] p-0 bg-white hover:bg-white transform translate-x-[-25%] translate-y-[17px] rounded-full right-0 top-0 border-0 transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
      onPointerDown={() => onClose()}
      disabled={disabled}
    >
      <X className="h-6 w-6 text-[#1F384C]" strokeWidth={3} />
      <span className="sr-only">Close</span>
    </Button>
  );
};

const DialogContent = ({
  className,
  children,
  hideClose = false,
  closeCallback = () => {},
  onPointerDownOutside = () => {},
  disabled = false,
  disabledClickOutside = false,
  ...props
}: ContentTypes) => {
  const contentRef: any = useRef();
  const refOverlay = useRef<HTMLDivElement>(null);
  const { isOpen, setIsOpen } = useDialog();
  const modalId: any = useMemo(
    () => (isOpen ? new Date().getTime().toString() : null),
    [isOpen]
  );
  useOutsideClickMultiple(
    [contentRef],
    () => {
      if (disabled || disabledClickOutside) return;
      if (!modalManager.isTopModal(modalId)) return;
      handleClose();
    },
    true
  );
  const handleKeydown = (event: KeyboardEvent) => {
    // Only the last modal need to be escaped when pressing the esc key
    if (event.keyCode !== 27 || !modalManager.isTopModal(modalId) || disabled)
      return;
    handleClose();
  };
  const handleOpen = () => {
    document?.addEventListener('keydown', handleKeydown);
  };
  const handleClose = () => {
    if (disabled) return;
    setIsOpen(false);
    document?.removeEventListener('keydown', handleKeydown);
    onPointerDownOutside();
    closeCallback();
  };
  useEffect(() => {
    // If the open prop is changing, we need to active effects, etc...
    // This is also called on the first render if the open prop is true when the modal is created
    if (isOpen) {
      handleOpen();
      safeDocument.body.style.overflow = 'hidden';
    } else {
      safeDocument.body.style.overflow = 'unset';
    }
  }, [isOpen]);
  useModalManager(modalId, isOpen);

  return isOpen ? (
    <DialogPortal>
      <div id={`dialog_${modalId}`}>
        <DialogOverlay ref={refOverlay}>
          <div
            ref={contentRef}
            className={cn(
              'box-border relative grid gap-8 w-full grid-rows-[auto_1fr_auto] min-w-[40%] max-w-[92.5%] md:max-w-lg rounded-[26px] bg-background p-8 md:p-14 md:pb-4 shadow-modal max-h-[95vh]',
              className
            )}
            {...props}
          >
            {children}
            {!hideClose && <Close onClose={handleClose} disabled={disabled} />}
          </div>
        </DialogOverlay>
      </div>
    </DialogPortal>
  ) : null;
};
const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'flex flex-col gap-8 text-center sm:text-left space-x-0 space-y-0',
        className
      )}
      {...props}
    />
  );
};
const DialogScrollWrapper = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('overflow-y-auto pr-[10px]', className)} {...props} />
  );
};
const DialogFooter = ({
  className,
  disableButtonStyling = false,
  ...props
}: FooterTypes) => {
  return (
    <div
      className={cn(
        'h-[62px] w-full lg:w-[80%] grid grid-cols-1 sm:grid-cols-2 items-start gap-8',
        disableButtonStyling ? 'h-auto' : styles.dialog_footer_buttons,
        className
      )}
      {...props}
    />
  );
};
const DialogTitle = ({ icon, className, ...props }: TitleTypes) => (
  <div className="flex items-center gap-4">
    {getDialogTitleIcon(icon)}
    <h2
      className={cn(
        'text-[22px] font-[350] leading-[30px] m-0 p-0 text-[#1F384C]',
        className
      )}
      {...props}
    />
  </div>
);
const DialogDescription = ({ className, ...props }: DescriptionTypes) => (
  <p
    className={cn(
      'text-[18px] text-[#53565A] leading-8 tracking-[0.058rem] font-light',
      className
    )}
    {...props}
  />
);
export const DialogPrimitive = {
  Dialog,
  DialogContent,
  DialogPortal,
  DialogOverlay,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogScrollWrapper
};
