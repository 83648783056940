export function validateEmail(email) {
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~’\-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

  const result = email.match(regex);
  const message = result
    ? ''
    : 'You have entered an invalid contact email address!';

  return [result, message];
}
