import { getAPIClient } from './axios';

export const bodySizeConfig = {
  api: {
    bodyParser: {
      sizeLimit: '4mb'
    }
  }
};

export const api = getAPIClient();
